import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    data()
    {
        return {
            gradient : null,
        }
    },
    watch: {
        chartData: function(newVal, oldVal) {
            // console.log("newVal", newVal);
            // console.log("oldVal", oldVal);
            this.renderChart({ labels: this.chartData.labels,
                datasets: [
                    {
                        label: this.chartData.datasets[0].label,
                        data:this.chartData.datasets[0].data,
                        borderColor: '#3B9E52',
                        pointBackgroundColor: '#3B9E52',
                        borderWidth: 1.8,
                        pointBorderColor: '#3B9E52',
                        pointRadius : 0,
                        tension : 0,
                        backgroundColor: this.gradient,
                    }
                ],
            }, this.options);
        }
    },
    mounted () {

        this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 450, 0, 0)


        this.gradient.addColorStop(0, 'rgba(54,159,79, 0.25)')
        this.gradient.addColorStop(0.50, 'rgba(0,0,0,0)');
        this.gradient.addColorStop(1, 'rgba(0,0,0,0)');

        // this.chartData is created in the mixin.
        // If you want to pass options please create a local options object
        // this.renderChart(this.chartData, this.options);
        this.renderChart({ labels: this.chartData.labels,
            datasets: [
                {
                    label: this.chartData.datasets[0].label,
                    data:this.chartData.datasets[0].data,
                    borderColor: '#3B9E52',
                    pointBackgroundColor: '#3B9E52',
                    borderWidth: 1.8,
                    pointBorderColor: '#3B9E52',
                    pointRadius : 0,
                    tension : 0,
                    backgroundColor: this.gradient,
                }
            ],
        }, this.options)
    }
}
